html {
    font-family: 'SoletoTK', sans-serif;
    box-sizing: border-box;
}

*, ::before, ::after {
    font-family: inherit;
    box-sizing: inherit;
}

body {
    margin: 0;
    overflow-y: scroll;
}

textarea,
select,
input,
button {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

ul, ol {
    margin: 0;
    padding: 0;
}

.grid-root-container {
    @include grid-root-container();
}

.standard-grid-item {
    @include grid-item(1, 6);
    @include grid-item-rows(1);
    @include from-width($m) {
        @include grid-item(1, 12);
    }
    @include from-width($l) {
        @include grid-item(2, 8);
    }
}

.block-grey {
    background-color: $color-anthracite-5;
    padding: 20px 30px;
}

.api-error {
    @include fontStrongS();
    color: $color-red-100;
    text-align: left;
}
