@import './colors';
@import 'basics';

select {
    background-color: $color-white;
    border: none;
    width: 100%;
    // max-width: 170px;
}

label {
    color: $color-anthracite-100;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0;

    &.disabled {
        cursor: not-allowed;
    }
}

.checkbox {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

$checkmarkSideLength: 32px;
.checkmark {
    position: absolute;
    top: 0;
    right: -$checkmarkSideLength;
    height: $checkmarkSideLength;
    width: $checkmarkSideLength;
    border: 1px solid $color-anthracite-50;

    &--small {
        top: 7px;
        right: -30px;
        height: 15px;
        width: 15px;
    }
}

label:hover .checkbox ~ .checkmark {
    border-color: $color-anthracite-100;
}

.checkbox:checked ~ .checkmark {
    background-color: $color-anthracite-100;
    border-color: $color-anthracite-100;
    background-size: 15px;
    background-image: url("/assets/icons/checkbox_checkmark.svg");
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
}

.checkbox:checked ~ .checkmark--small {
    background-size: 7px;
}
