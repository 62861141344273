@import 'colors.scss';

.table-container {
    table {
        @include fontTextS;
        border-collapse: collapse;
        width: 100%;

        tr {
            border-bottom: 1px solid $color-grey05;
            line-height: 2.14;

            &:hover {
                background-color: $color-anthracite-5;
            }

            &:last-of-type {
                border-bottom: none;
            }

            &.highlight {
                background-color: $color-anthracite-10;
            }

            th, td {
                border-bottom: none;
            }
        }

        .table-link {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.protocol-wrapper {
    margin-top: 30px;
}
