@import "../styles/basics";

.modalBackdropContainer {
    background-color: #ffffff;
}

.modalContainer {
    .modal-content {
        border-radius: 0;
        box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.1);
    }

    z-index: 1060!important;
}
