$spacing: 20px;

.spacer--top-xl {
    margin-top: 50px;
}

.spacer--top {
    margin-top: $spacing;
}

.align-right {
    text-align: right;
}

.decent {
    @include fontTextS;
}
