/* You can add global styles to this file, and also import other style files */

//@import './app/styles/selected-bootstrap';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'app/styles/custom-modal.scss';
@import 'app/styles/table.scss';
@import 'app/styles/font-import.scss';
@import './app/styles/basics.scss';
@import './app/styles/essentials.scss';
@import './app/styles/colors.scss';
@import 'app/styles/forms';
@import 'app/styles/buttons';
@import 'app/styles/helpers';

body {
    background-color: $color-grey-30;
    color: $color-anthracite-100;
}

.main {
    padding: 50px 0;
    margin: 0 auto 30px;
    max-width: 1000px;
    min-width: 1000px;

    &--white {
        background-color: $color-white;
        padding: 50px;
    }

    &--noBottomMargin {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.form-row {
    align-items: center;
    margin: 0 0 20px 0;
}

.form {
    padding: 40px 0 0;

    label {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: right;
    }

    input {
        border: 1px solid $color-anthracite-50;
        padding: 10px;
        transition: border-color 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940), box-shadow 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940);

        &::placeholder {
            color: $color-anthracite-20;
        }

        &:focus {
            border: 1px solid $color-anthracite-50;
            box-shadow: 0 0 4px 0 $color-anthracite-30;
        }

        &:hover {
            border: 1px solid $color-anthracite-100;
        }

        &:disabled,
        &:read-only {
            color: $color-anthracite-20;
            background-color: $color-anthracite-5;
            border-color: $color-anthracite-20;
        }

        &.ng-invalid.ng-touched {
            border: 1px solid $color-red;
        }
    }
}
