@import 'basics';
@import './colors';

.btn {
    border: none !important;
    border-radius: 0;
    color: $color-anthracite-100;
    cursor: pointer;
    display: inline-block;
    font-family: $fontText;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.563rem;
    margin-bottom: 20px;
    padding: 13px 20px 14px;
    position: relative;
    text-align: left;
    text-decoration: none;

    &:not(&.btn-fullWidth) {
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }

}

.btn-primary {
    background-color: $color-anthracite-100;
    border: 2px solid $color-anthracite-100;
    color: $color-grey-30;

    &:hover,
    &:active,
    &:focus {
        background-color: $color-anthracite-100 !important;
    }

    &:disabled {
        background-color: #e4e4e3 !important;
        color: $color-black;
        cursor: not-allowed;
    }
}

.no-btn {
    background: none;
    border: none;
    color: $color-anthracite-100;
    cursor: pointer;
}

.btn-fullWidth {
    width: 100%;
}
